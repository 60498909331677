import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5dc02955&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc02955",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicsIconsAppStore: require('/builds/athlyzer-devs/athlyzer-website/components/basics/icons/app-store.vue').default,BasicsIconsPlayStore: require('/builds/athlyzer-devs/athlyzer-website/components/basics/icons/play-store.vue').default})
